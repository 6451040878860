@media only screen and (max-width: 1100px) {
  .slide-wrapper {
    flex-direction: column;
    margin-right: 39.5px;
  }

  .slide-wrapper img {
    margin: auto;
    width: 65% !important;
  }

  .project-content h3 {
    margin-top: 10px;
    text-align: center;
  }

  .project-content h4 {
    text-align: center;
  }

  .project-technologies {
    margin: auto;
  }
}

@media only screen and (max-width: 870px) {
  .nav-name {
    margin-left: 20px;
    color: rgb(130, 249, 161);
    background: -webkit-linear-gradient(rgb(176, 251, 188), rgb(130, 249, 161));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  ul {
    padding: 0 0 0 20px;
  }

  div {
    display: block;
  }

  h3 {
    letter-spacing: 3px;
    font: 20px 'Lato', sans-serif;
  }

  .burger-icon-a {
    float: right;
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 40px;
    color:rgb(130, 249, 161);
    padding: 0px 5px;
    border: none;
    outline: none;
    background-color: transparent;
  }

  .burger-icon {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-size: 40px;
    color: white;
  }

  .burger-icon:before {
    content: "\f0c9";
  }

  .top-nav {
    width: 100%;
    position: fixed;
    z-index: 10;
    background-color: rgba(13, 21, 26, 0.78) !important;
    backdrop-filter: blur(5px);
    height: auto;
  }

  #top-nav.active .burger-icon:hover{
    color:  rgb(130, 249, 161)
  }

  .burger-icon:hover {
    color: rgb(130, 249, 161)
  }

  .scrolled {
    background-color: #fff !important;
    transition: background-color 200ms linear;
    opacity: .85;
    box-shadow: 0 8px 6px -6px #bfbfbf;
  }

  .nav-icon {
    width: 30%;
  }

  .icon {
    width: 60px;
    padding-top: 15px;
    padding-left: 15px;
  }

  .nav-links {
    display: none;
    transition: background-color 200ms linear;
  }

  .nav-links button {
    color: white;
    text-decoration: none;
    padding-left: 40px;
    font-size: 25px;
    letter-spacing: 2px;
    display: block;
    border: none;
    background: none;
    width: 100%;
    text-align: left;
    outline: none;
  }

  
  .nav-links button:hover {
    background-color: rgba(124, 124, 124, 0.30);
    color: rgb(130, 249, 161);
  }

  #jump-about, #jump-projects, #jump-experience, #jump-contact {
    position: absolute;
    top: -82px;
  }

  button hr {
    width: 100%;
    border: none;
    height: 2px;
    background-color: #fff;
    display: none;
  }

  .nav-links button:visited, .nav-links button:active {
    text-decoration: none;
    color: black;
    outline: none;
  }

  .caption {
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    text-align: center;
    color: #000;
  }

  #bgimg-main .caption {
    display: flex;
    justify-content: center;
  }

  .caption span.border {
    background-color: #595959;
    color: #fff;
    padding: 18px;
    font-size: 25px;
    letter-spacing: 10px;
  }

  .caption p.border {
    background-color: #595959;
    color: #fff;
    padding: 18px;
    font-size: 25px;
  }
  
  .caption span.border-2 {
    color: #fff;
    padding: 18px;
    font-size: 25px;
    letter-spacing: 10px;
  }

  .bgimg {
    position: relative;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    opacity: .85;
  }

  #section-about, #section-projects {
    color: white;
    text-align: justify;
    align-items: center;
    position: relative;
    background-color: rgb(22, 33, 41);
  }

  #section-about {
    padding: 50px 20px 25px 20px;
  }

  #section-project {
    padding: 25px 70px;
  }

  #section-about, #section-projects {
    display: flex;
    flex-direction: column;
  }
  
  #section-contact #about {
    height: 1vh
  }

  #about h2 {
    color: rgb(130, 249, 161);
    background: -webkit-linear-gradient(rgb(176, 251, 188), rgb(130, 249, 161));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  #headshot {
    max-height: 200px;
    border-radius: 50%;
    margin: 50px auto 10px auto;
  }

  #technologies {
    display: flex;
    font-size: 14px;
  }

  #tax-hub-img, #otaku-risuto-img, #spaced-repetition-img {
    border: 2px solid white;
    width: 95%;
  }

  .project-content, .img-wrapper {
    margin-top: 20px;
  }

  .img-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 40%;
  }

  #project-tax-hub, #project-otaku-risuto, #project-spaced-repetition {
    display: flex;
    flex-direction: column;
    text-align: justify;
    padding-bottom: 25px;
  }
  
  .project-content {
    flex: 2;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
  }

  .img-wrapper {
    flex: 1;
  }

  .visit-link, .visit-link:active, .download-link, .download-link:active {
    text-decoration: none;
    color: white;
    text-decoration: none;
    text-align: center;
    padding: 8px;
    border: 1px solid white;
    border-radius: 6px;
    margin-right: 10px;
  }

  .visit-link:hover, .download-link:hover {
    background-color: rgba(255, 255, 255, 0.24)
  }

  .project-ul {
    margin-top: 0;
  }

  #education-header, #experience-button {
    background-color: #282e34;
    color: #ddd;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    outline: none;
    text-align: left;
    font-size: 25px;
    letter-spacing: 5px;
    transition: 0.4s;
    display: flex;
    justify-content: space-between;
  }

  #education-button:hover, #experience-button:hover {
    background-color: #2c333a;
  }

  .hidden {
    display: none;
  }

  .accordion{
    background-color: #011936;
    color: white;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    outline: none;
    text-align: left;
    font-size: 25px;
    letter-spacing: 5px;
    transition: 0.4s;
  }

  .active-accorion, .accordion:hover {
    background-color: rgba(0,0,0, .35)
  }

  .accordion:after {
    content: '\02795'; /* Unicode character for "plus" sign (+) */
    font-size: 13px;
    color: #777;
    float: right;
    margin-left: 5px;
  }

  .active-accorion:after {
    content: "\2796"; /* Unicode character for "minus" sign (-) */
  }

  .panel {
    padding: 0 18px;
    background-color: #595959;
    display: none;
    overflow: hidden;
  }

  .contact-icon-container img {
    width: 45px;
    margin-right: 5px;
  }

  .download-link {
    float: right;
    margin: 0 0 15px 0!important
  }

  .education-header h3 span, .experience-header h3 span {
    display: inline-block;
  }

  .education-header h3 {
    letter-spacing: 0 !important;
    text-align: left;
  }

  #section-experience li {
    text-align: left;
  }

  
  #section-projects p {
    text-align: left;
  }

  .projects-header {
    color: rgb(130, 249, 161);
    letter-spacing: 2px;
    margin-bottom: 35px;
  }

  .slide-wrapper img {
    margin: auto;
    width: 85% !important;
  }

  .project-content h3 {
    margin-top: 10px;
    text-align: center;
    color: rgb(130, 249, 161)
  }

  .project-technologies {
    margin: auto;
  }

  .project-content h4 {
    text-align: center;
    margin-bottom: 4px;
  }

  #technologies ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }

  .project-button-container {
    margin: 20px auto 0 auto;
  }

  .project-content p {
    margin: 0;
  }

  #nav-experience {
    padding-bottom: 5px;
  }
}




