

body {
  font: 400 15px/1.5 'Lato', sans-serif;
  background-color: rgb(22, 33, 41);
}

main {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: auto;
}


@media only screen and (min-width: 870px) {
  div {
    display: block;
  }

  h3 {
    letter-spacing: 3px;
    font: 20px 'Lato', sans-serif;
  }

  .top-nav {
    width: 100%;
    position: fixed;
    z-index: 10;
    background-color: rgba(13, 21, 26, 0.78) !important;
    backdrop-filter: blur(5px);
  }

  .top-nav-wrapper {
    max-width: 1200px;
    margin: auto;
  }

  #top-nav a.burger-icon-a{
    display: none;
  }

  .nav-name {
    padding-left: 70px;
    color: rgb(130, 249, 161);
    background: -webkit-linear-gradient(rgb(176, 251, 188), rgb(130, 249, 161));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .icon {
    width: 60px;
    padding-top: 15px;
    padding-left: 45px;
  }

  .burger-icon-a{
    display: none;
  }

  .nav-links {
    float: right;
    margin-top: -50px;
    margin-right: 60px;
  }

  .nav-links button {
    color:  white;
    margin-left: 10px;
    margin-right: 10px;
    text-decoration: none;
    font-size: 14px;
    letter-spacing: 2px;
    display: block;
    float: left;
    border: none;
    background: none;
    outline: none;
  }

  #jump-about, #jump-projects, #jump-experience, #jump-contact {
    position: absolute;
    top: -82px;
  }

  .nav-links button:visited, .nav-links button:active, .nav-links button:hover {
    text-decoration: none;
    color: rgb(130, 249, 161);
    outline: none;
  }

  .caption {
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    text-align: center;
    color: #000;
  }

  #bgimg-main .caption {
    display: flex;
    justify-content: center;
  }

  .caption p.border {
    background-color: #595959;
    color: #fff;
    padding: 18px;
    font-size: 25px;
  }

  .caption span.border {
    background-color: #595959;
    color: #fff;
    padding: 18px;
    font-size: 25px;
    letter-spacing: 10px;
  }
  
  .caption span.border-2 {
    color: #fff;
    padding: 18px;
    font-size: 25px;
    letter-spacing: 10px;
  }
    
  .bgimg {
    position: relative;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    opacity: .85;
  }

  #section-about, #section-projects {
    padding: 50px 70px;
    text-align: justify;
    align-items: center;
    position: relative;
    margin: auto;
  }

  #section-about {
    padding: 50px 70px 25px 70px;
    background-color: rgb(22, 33, 41);
    display: flex;
    flex-direction: column;
    color: white;
  }
  
  #about h2 {
  color: rgb(130, 249, 161);
    background: -webkit-linear-gradient(rgb(176, 251, 188), rgb(130, 249, 161));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  #about p {
    font-size: 16px;
  }

  #section-projects {
    background-color: rgb(22, 33, 41);
    color: white;
    display: flex;
    flex-direction: column;
    padding: 25px 70px;
  }

  .projects-header {
    color: rgb(130, 249, 161);
    letter-spacing: 2px;
    margin-bottom: 35px;
  }

  #section-experience {
    background-color: rgb(22, 33, 41);
    color: white;
  }

  #section-about, #section-projects, #section-contact {
    display: flex;
  }
  
  #section-contact #about {
    height: 1vh
  }

  #headshot {
    max-height: 200px;
    border-radius: 50%;
    margin: 50px auto 10px auto;
  }

  #technologies {
    display: flex;
    font-size: 14px;
  }

  #technologies ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }


  #tax-hub-img, #otaku-risuto-img, #spaced-repetition-img {
    border: 2px solid white;
    width: 95%;
  }

  .img-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 40%;
  }

  #project-tax-hub, #project-otaku-risuto, #project-spaced-repetition {
    display: flex;
    flex-direction: row;
    text-align: justify;
    padding-bottom: 25px;
  }
  
  .project-content {
    margin-left: 55px;
    margin-right: 40px;
    display: flex;
    flex-direction: column;
  }

  .project-content h3 {
    margin-top: 0;
    margin-bottom: 4px;
    color:  rgb(130, 249, 161);
  }

  .project-content h4 {
    margin-bottom: 4px;
    text-align: center;

  }
  
  .project-content p {
    margin: 0;
  }


  .img-wrapper {
    flex: 1;
  }

  .visit-link, .visit-link:active, .download-link, .download-link:active {
    text-decoration: none;
    color: white;
    text-decoration: none;
    text-align: center;
    padding: 8px;
    border: 1px solid white;
    border-radius: 6px;
    margin-right: 10px;
  }

  .visit-link:hover {
    background-color: rgba(255, 255, 255, 0.24)
  }

  .download-link:hover {
    background-color: rgba(150, 150, 150, 0.25)
  }

  .project-ul {
    margin-top: 0;
  }

  .project-button-container {
    margin: 20px auto 0 auto;
    
  }

  .project-technologies {
    margin: auto;
  }

  .education-experience-container {
    display: flex;
    flex-direction: row;
  }

  .education-wrapper {
    flex: 1;
  }

  .experience-wrapper {
    flex: 1;
  }


  #education-header, #experience-button {
    background-color: #282e34;
    color: #ddd;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    outline: none;
    text-align: left;
    font-size: 25px;
    letter-spacing: 5px;
    transition: 0.4s;
    display: flex;
    justify-content: space-between;
  }


  .hidden {
    display: none;
  }

  .accordion{
    background-color: #011936;
    color: white;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    outline: none;
    text-align: left;
    font-size: 25px;
    letter-spacing: 5px;
    transition: 0.4s;
  }

  .active-accorion, .accordion:hover {
    background-color: rgba(150, 150, 150, 0.25)
  }

  .panel {
    padding: 0 18px;
    background-color: #595959;
    display: none;
    overflow: hidden;
  }

  .contact-icon-container img {
    width: 45px;
    margin-right: 5px;
  }

  .download-link {
    float: right;
    margin: 0 0 15px 0!important
  }

  .education-header h3, .experience-header h3 {
    display: flex;
    justify-content: space-between;
  }

  #section-experience li, #section-projects p, #section-about p {
    text-align: left;
  }
}

* {box-sizing:border-box}

/* Slideshow container */
.slideshow-container {
  position: relative;
  margin: auto;
}

.slide-wrapper {
  display: flex;
}

/* Hide the images by default */
.mySlides {
  display: none;
  margin-left: 39.5px;
}

/* Next & previous buttons */
.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -35px;
  padding: 5px 10px 5px 10px;
  color: rgb(130, 249, 161);
  font-weight: bold;
  font-size: 36px;
  transition: 0.6s ease;
  border-radius: 3px 0 0 3px;
  user-select: none;
}

.next {
  border-radius: 0 3px 3px 0 !important;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover, .next:hover {
  background-color:  rgba(53, 88, 110, 0.541)
}

/* Caption text */
.text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active, .dot:hover {
  background-color: rgb(130, 249, 161)
}

/* Fading animation */
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

@-webkit-keyframes fade {
  from {opacity: .4}
  to {opacity: 1}
}

@keyframes fade {
  from {opacity: .4}
  to {opacity: 1}
}




